import React, { useEffect, useState } from "react";
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import Sheet from "@mui/joy/Sheet";
import Box from "@mui/material/Box";
import { Tab } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import { useDispatch, useSelector } from "react-redux";

import axios from "axios";
import { loadDocument } from "../../Document/documentSlice.js";
import { FileSelectorTable } from "./FileSelectorTable.jsx";

function FileModal(
  {
    show,
    handleClose,
    showSharedWithMe,
    setTitle,
    setStaffElements,
    setDialogue,
  },
) {
  const dispatch = useDispatch();
  const [tabValue, setTabValue] = React.useState("1");
  const handleMUIChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const [files, setFiles] = useState({
    my_files: [],
    shared_with_me: [],
  });

  const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;
  const user = useSelector((state) => state.user);

  function sortByISO8601(array, key = null) {
    return array.sort((a, b) => {
      const dateA = key ? new Date(a[key]) : new Date(a);
      const dateB = key ? new Date(b[key]) : new Date(b);

      return dateB - dateA; // Descending order
    });
  }
  useEffect(() => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${user.token}`,
      },
    };
    async function getDocuments() {
      try {
        const response = await axios.get(
          apiEndpoint + "/document/",
          config,
        );
        const nextFiles = {
          my_files: sortByISO8601(response.data.my_files, "updated_at"),
          shared_with_me: sortByISO8601(
            response.data.shared_with_me,
            "updated_at",
          ),
        };
        setFiles(nextFiles);
      } catch (error) {
        console.log(error);
      }
    }
    getDocuments();
  }, [show]);

  const handleLoadFile = (fileID) => {
    async function getDocument(fileID) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${user.token}`,
        },
      };
      try {
        const response = await axios.get(
          apiEndpoint + `/document/${fileID}/`,
          config,
        );

        // Hacky way to allow useState based variables to update when a file is loaded
        // until I replace all instances with redux
        if (!showSharedWithMe) {
          setDialogue(response.data.dialogue);
          setTitle(response.data.title);
          setStaffElements({
            elements: response.data.elements,
            sections: response.data.sections,
          });
        }
        dispatch(loadDocument(response.data));
      } catch (error) {
        console.log(error);
      }
    }
    getDocument(fileID);
  };

  const handleLoadViewerFile = (fileID) => {
    async function getDocument(fileID) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${user.token}`,
        },
      };
      try {
        const response = await axios.get(
          apiEndpoint + `/document/${fileID}/view`,
          config,
        );
        dispatch(loadDocument(response.data));

        // setData(response.data);
      } catch (error) {
        console.log(error);
      }
    }
    getDocument(fileID);
  };

  function ControlledTabsExample() {
    return (
      <TabContext value={tabValue}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            onChange={handleMUIChange}
            aria-label="lab API tabs example"
          >
            <Tab label="My Files" value="1" />
            {showSharedWithMe && <Tab label="Shared with me" value="2" />}
          </TabList>
        </Box>
        <TabPanel value="1" sx={{ padding: "0" }}>
          <FileSelectorTable
            tabValue={tabValue}
            files={files.my_files}
            handleLoadFile={handleLoadFile}
            handleClose={handleClose}
          />
        </TabPanel>
        <TabPanel value="2" sx={{ padding: "0" }}>
          <FileSelectorTable
            tabValue={tabValue}
            files={files.shared_with_me}
            handleLoadFile={handleLoadViewerFile}
            handleClose={handleClose}
          />
        </TabPanel>
      </TabContext>
    );
  }

  return (
    <React.Fragment>
      <Modal
        aria-labelledby="File Modal"
        aria-describedby="Choose file to open"
        open={show}
        onClose={() => handleClose()}
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          height: "100%",
          overflow: "scroll",
        }}
      >
        <Sheet
          variant="outlined"
          sx={{
            top: "10vh",
            maxWidth: "80%",
            position: "absolute",
            background: "#f8f8f8",
            borderRadius: "md",
            p: 1,
          }}
        >
          <ModalClose variant="plain"  />
          <ControlledTabsExample />
        </Sheet>
      </Modal>
    </React.Fragment>
  );
}
export default FileModal;
