import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Copyright from "../Common/Copyright";
import PresentationStaff from "../Presentation/PresentationStaff";
import axios from "axios";
import { createTheme } from "@mui/material";
import SwsengLogo from "../../static/images/SWSENG_Logo_Cropped.png";
import Select from "react-select";
import { Alert, Box, IconButton, Typography } from "@mui/material";
import ScreenRotationIcon from "@mui/icons-material/ScreenRotation";
import Stack from "@mui/joy/Stack";
import LinearProgress from "@mui/material/LinearProgress";
import PresentationMeasureControl from "../Presentation/PresentationMeasureControl.jsx";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import { loadDocument, clearDocument } from "../Document/documentSlice.js";

export default function PresentationPublic() {
  const documentState = useSelector((state) => state.document);
  const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { uuid } = useParams();
  const staffElements = {
    elements: documentState.elements,
    sections: documentState.sections,
  };
  const { title, dialogue, id: documentId, viewer } = documentState;

  const selectedElementOptions = documentState.elements.map((element) => ({
    value: element,
    label: element,
  }));

  const apiKey = process.env.REACT_APP_API_KEY;
  // Fix and remove this from: Presentation, Landing, FileModal, ShareModal

  // all Sections for handleSelect
  const allSections = staffElements.sections.map((sec) => sec.sectionName);

  const [measure, setMeasure] = React.useState(1);
  const [selectedElement, setSelectedElement] = React.useState(null);
  const [sectionExpanded, setSectionExpanded] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isSuccess, setIsSuccess] = React.useState(false);

  async function getPublicDocument() {
    // const body = JSON.stringify({ username, password });
    setIsLoading(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Swseng-Api-Key": apiKey,
      },
    };
    try {
      const response = await axios.get(
        apiEndpoint + `/document/public/${uuid}`,
        // body,
        config,
      );
      setIsSuccess(true);
      setIsLoading(false);
      // console.log(response.data);
      dispatch(loadDocument(response.data));
    } catch (error) {
      setIsSuccess(false);
      setIsLoading(false);
      // console.error(error);
    }
  }

  React.useEffect(() => {
    getPublicDocument();
    setSelectedElement(null);
  }, []);

  const handleSelectElement = (e) => {
    if (e === null) {
      setSelectedElement(null);
      setSectionExpanded(allSections);
    } else {
      // Sections Automatically Expand when  you choose a element actor
      const nextSectionsExpanded = staffElements.sections.filter((s) =>
        s.elements.find((se) => se.elementName === e.value)
      ).map((s) => s.sectionName);
      setSectionExpanded(nextSectionsExpanded);
      setSelectedElement(e.value);
    }
  };

  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
  });

  function ToolBar() {
    return (
      <Box
        sx={{
          position: "relative",
          top: "0px",
          backgroundColor: "#f8f8f8",
          alignContent: "baseline",
          display: "grid",
          gridTemplateColumns: "repeat(12, 1fr)",
          height: "fit-content",
          gap: "4px",
          // columnGap: "8px",
          // rowGap: "8px",
          boxShadow: "0 4px 2px -2px gray",
          justifyContent: "start",
          zIndex: "2",
          py: "8px",
          pl: 2,
        }}
      >
        <Box
          sx={{
            display: "grid",
            gridColumn: "1 / 1",
            alignSelf: "center",
            // paddingLeft: 2,
            // maxWidth: "fit-content",
          }}
        >
          <IconButton
            sx={{ borderRadius: 2, padding: 0 }}
            component={RouterLink}
            onClick={() => dispatch(clearDocument())}
            to={"/"}
          >
            <img
              src={SwsengLogo}
              style={{
                maxHeight: "56px",
                width: "auto",
              }}
            />
          </IconButton>
        </Box>
        <Box
          sx={{
            gridColumn: "2 / 9",
            display: "flex",
            flexDirection: "column",
            [theme.breakpoints.only("xs")]: {
              display: "none",
            },
            // gap: "12px",

            // [theme.breakpoints.down("md")]: {
            //   gridColumn: "2 / 7",
            //   gridRow: "1",
            // },
            // gridColumn: "2 / 5",
            // gridRow: "1",
            alignSelf: "center",
          }}
        >
          <Box display="flex" flexDirection={"column"} // gap={"24px"}
          >
            {documentId !== null
              ? (
                <Typography
                  variant="h5"
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    mr: 1,
                  }}
                >
                  {title}
                </Typography>
              )
              : (null)}
            <Select
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderRadius: "14px",
                  maxWidth: "24ch",
                }),
              }}
              onChange={(e) => handleSelectElement(e)}
              isClearable={true}
              isSearchable={false}
              value={selectedElementOptions.filter(function(option) {
                return option.value === selectedElement;
              })}
              placeholder="Select element"
              options={selectedElementOptions}
            />
          </Box>
        </Box>
        <Box
          sx={{
            gridColumn: "9 / -1",
            display: "flex",
            flexDirection: "column",
            // gap: "12px",

            [theme.breakpoints.only("xs")]: {
              display: "none",
            },
            // gridColumn: "2 / 5",
            // gridRow: "1",
            alignSelf: "flex-end",
          }}
        >
          <PresentationMeasureControl
            measure={measure}
            setMeasure={setMeasure}
          // handleClearCharacterFocus={handleClearCharacterFocus}
          />
        </Box>
        <Box
          sx={{
            gridColumn: "2 / -1",
            display: "none",
            flexDirection: "column",
            // alignSelf: "center",
            // justifySelf: "center",
            // gap: "12px",

            [theme.breakpoints.only("xs")]: {
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            },
            // gridColumn: "2 / 5",
            // gridRow: "1",
          }}
        >
          <Alert
            severity="info"
            sx={{ display: "flex", justifyContent: "center" }}
            icon={<ScreenRotationIcon sx={{ paddingRight: "4px" }} />}
          >
            Rotate your device
          </Alert>
        </Box>
      </Box>
    );
  }

  const LoadingContent = () => {
    return (
      <div
        className="editor-container"
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Stack
          spacing={2}
          direction="column"
          sx={{
            background: "#f0f0f0",
            padding: "1rem 1rem",
            minWidth: "600px",
            maxWidth: "900px",
            height: "fit-content",
            marginTop: "2rem",
            borderRadius: "0.5rem",
          }}
        >
          <Typography variant="h4" sx={{ textAlign: "center" }}>
            Loading
          </Typography>
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        </Stack>
      </div>
    );
  };

  const FailedLoad = () => {
    return (
      <div
        className="editor-container"
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Stack
          spacing={2}
          direction="column"
          sx={{
            background: "#f0f0f0",
            padding: "1rem 1rem",
            minWidth: "600px",
            maxWidth: "900px",
            height: "fit-content",
            marginTop: "2rem",
            borderRadius: "0.5rem",
          }}
        >
          <Typography variant="h5" sx={{ textAlign: "center" }}>
            The script you requested does not exist
          </Typography>
          <Typography variant="body1">
            {"Check the URL and if the problem persists give us an email: "}
            <a href="mailto:info@swseng.io">
              info@swseng.io
            </a>
          </Typography>
        </Stack>
      </div>
    );
  };

  return (
    <div style={{ display: "grid", height: "100vh" }}>
      <ToolBar />
      {isLoading ? <LoadingContent /> : isSuccess
        ? (
          <PresentationStaff
            elements={staffElements.elements}
            sections={staffElements.sections}
            dialogue={dialogue}
            page={measure}
            setPage={setMeasure}
            sectionExpanded={sectionExpanded}
            setSectionExpanded={setSectionExpanded}
            selectedElement={selectedElement}
            documentId={documentId}
          />
        )
        : <FailedLoad />}
      {
        /*
      <div className="bottom-toolbar-container">
        <Copyright />
      </div>
      */
      }
    </div>
  );
}
