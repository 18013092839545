import React from "react";
import SwsengLogo from "../../static/images/SWSENG_Logo_Cropped.png";
import { useDispatch, useSelector } from "react-redux";
import { createTheme } from "@mui/material";
import Select from "react-select";
import ShareButton from "../Layout/ShareButton.jsx";
import { Box, Button, IconButton, Typography } from "@mui/material";
import PresentationMeasureControl from "./PresentationMeasureControl.jsx";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import AvatarButton from "../Layout/AvatarButton.jsx";
import { logoutUser } from "../User/userSlice.js";
import { clearDocument, loadDocument } from "../Document/documentSlice.js";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});
export default function PresentationToolbar(
  {
    selectedElementOptions,
    selectedElement,
    measure,
    setMeasure,
    handleSelectElement,
    handleShowShareModal,
    handleShowFileModal,
  },
) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector((state) => state.user);
  const documentState = useSelector((state) => state.document);
  const { title, dialogue, id: documentId, viewer } = documentState;

  const handleLogOut = () => {
    dispatch(clearDocument());
    dispatch(logoutUser());
    navigate("/");
  };

  return (
    <Box
      sx={{
        display: "grid",
        position: "relative",
        top: "0px",
        gridTemplateColumns: "repeat(12, 1fr)",
        columnGap: "4px",
        rowGap: "8px",
        height: "fit-content",
        backgroundColor: "#f8f8f8",
        boxShadow: "0 4px 2px -2px gray",
        justifyContent: "start",
        alignContent: "baseline",
        zIndex: "2",
        py: "4px",
      }}
    >
      <Box
        sx={{
          display: "grid",
          gridColumn: "1 / 1",
          alignSelf: "center",
        }}
      >
        <IconButton
          sx={{ borderRadius: 2, ml: 1 }}
          component={RouterLink}
          onClick={() => dispatch(clearDocument())}
          to={"/"}
        >
          <img
            src={SwsengLogo}
            style={{
              maxHeight: "56px",
              width: "auto",
            }}
          />
        </IconButton>
      </Box>
      <Box
        sx={{
          display: "flex",
          [theme.breakpoints.down("md")]: {
            gridColumn: "2 / 11",
            gridRow: "1",
          },
          gridColumn: "2 / 5",
          gridRow: "1",
          alignSelf: "center",
        }}
      >
        {documentId !== null
          ? (
            <Typography
              variant="h5"
              sx={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                mr: 1,
              }}
            >
              {title}
            </Typography>
          )
          : (null)}
        <Box
          style={{
            backgroundColor: "#fff",
            border: "1px solid rgb(217, 217, 217)",
            width: "fit-content",
            borderRadius: "14px",
            paddingBottom: "0px",
            marginBottom: "0px",
            height: "fit-content",
            alignSelf: "center",
          }}
        >
          <Button
            sx={{ color: "grey" }}
            onClick={() => handleShowFileModal()}
          >
            {documentId !== null ? "Change" : "Choose File"}
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          // display: "grid",
          alignSelf: "center",
          backgroundColor: "#f8f8f8",

          [theme.breakpoints.down("md")]: {
            gridColumn: "1 / 7",
            gridRow: "2",
            ml: 1,
          },
          gridColumn: "5 / 8",
          gridRow: "1",
          // width: "fit-content",
        }}
      >
        <Select
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              borderRadius: "14px",
            }),
          }}
          onChange={(e) => handleSelectElement(e)}
          isClearable={true}
          value={selectedElementOptions.filter(function(option) {
            return option.value === selectedElement;
          })}
          placeholder="Select element"
          options={selectedElementOptions}
        />
      </Box>

      <Box
        sx={{
          display: "grid",
          alignSelf: "center",
          gridColumn: "9 / 10",
          gridRow: "1",
          [theme.breakpoints.down("md")]: {
            gridColumn: "8 / 12",
            gridRow: "2",
          },
        }}
      >
        <PresentationMeasureControl
          measure={measure}
          setMeasure={setMeasure}
        />
      </Box>
      <Box
        sx={{
          display: "grid",
          gridColumn: "11 / 11",
          alignItems: "center",
          alignSelf: "center",
          gridRow: "1",
        }}
      >
        {documentState.owner === user.username
          ? (
            <ShareButton
              handleShow={handleShowShareModal}
              disabled={documentId === null}
              viewer={viewer}
            />
          )
          : (null)}
      </Box>
      <Box
        sx={{
          display: "grid",
          gridColumn: "12 / 12",
          gridRow: "1 / -1",
          maxWidth: "fit-content",
        }}
      >
        <AvatarButton
          handleLogOut={handleLogOut}
          user={user}
        />
      </Box>
    </Box>
  );
}
