import React, { useEffect, useState } from "react";
import { Button, Input, Typography } from "@mui/joy";
import { Box, CircularProgress, Grow, InputLabel, Stack } from "@mui/material";
import Select from "react-select";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useDispatch, useSelector } from "react-redux";

import { loadDocument } from "../../Document/documentSlice.js";
import axios from "axios";
import { CurrencyExchange, LockOpen } from "@mui/icons-material";

export default function ShareLink({ currentDocument, user }) {
  const [isLoading, setIsLoading] = React.useState(false);

  const FRONTEND_DOMAIN = process.env.REACT_APP_FRONTEND_DOMAIN;
  const dispatch = useDispatch();

  const handleTogglePublic = () => {
    setIsLoading(true);
    async function togglePublic() {
      const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${user.token}`,
        },
      };
      const payload = {
        public: !currentDocument.public,
      };
      try {
        const response = await axios.patch(
          apiEndpoint + `/document/${currentDocument.id}/`,
          payload,
          config,
        );
        dispatch(loadDocument(response.data));
        setIsLoading(false);
        // setDocumentId(response.data.id);
        // setTitle(response.data.title);
        // setData(response.data);
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    }
    togglePublic();
  };
  return (
    <Box>
      <Stack
        direction="column"
        spacing={1}
        sx={{ mt: 1 }}
      >
        <Typography level="h4">
          Share read only link:
        </Typography>
        {currentDocument.public
          ? (
            <Stack
              direction="row"
              spacing={2}
              sx={{
                p: 1,
                alignItems: "center",
                justifyContent: "space-between",
                background: "#f0f0f0",
                borderRadius: "0.5rem",
              }}
            >
              <Stack 
                direction="row" 
                spacing={2}
                sx={{ minWidth: "80%" }}
              >
                <LockOpenOutlinedIcon
                  sx={{ background: "#e0e0e0", p: 1, borderRadius: "50%" }}
                />

                <Input
                  fullWidth={true}
                  variant="plain"
                  size="sm"
                  value={`${FRONTEND_DOMAIN}/p/${currentDocument.uuid}`}
                  endDecorator={
                    <Button
                      onClick={() => {
                        navigator.clipboard.writeText(
                          `${FRONTEND_DOMAIN}/p/${currentDocument.uuid}`,
                        );
                      }}
                      variant="outlined"
                    >
                      Copy
                    </Button>
                  }
                />
              </Stack>
              <Button
                size="sm"
                onClick={handleTogglePublic}
                disabled={isLoading}
                startDecorator={<LockOutlinedIcon />}
                sx={{ justifySelf: "flex-end", minWidth: "15%" }}
              >
                Make Private
              </Button>
            </Stack>
          )
          : (
            <Stack
              direction="row"
              spacing={2}
              sx={{
                p: 1,
                alignItems: "center",
                justifyContent: "space-between",
                background: "#f0f0f0",
                borderRadius: "0.5rem",
              }}
            >
              <Stack direction="row" spacing={2} sx={{ width: "75%" }}>
                <LockOutlinedIcon />
                <Typography level="p">
                  Scripts are private by default.
                </Typography>
              </Stack>
              <Box sx={{ width: "25%" }}>
                <Button
                  onClick={handleTogglePublic}
                  disabled={isLoading}
                  startDecorator={<LockOpenOutlinedIcon />}
                  sx={{ justifySelf: "flex-end" }}
                >
                  Make Public
                </Button>
              </Box>
            </Stack>
          )}
      </Stack>
    </Box>
  );
}
