import React, { useEffect, useRef, useState } from "react";

export default function TransitionStaffLine(
  {
    page,
    section,
    element,
    actualCharacterDialogue,
    backgroundStyling,
    showStageDirections,
    transitionAction,
    transitions,
    setTransitions,
    characterFocusId,
    rowStyle,
    staffElements,
    setStaffElements,
  },
) {
  const dialogueRef = React.useRef(null);
  const [verticalLineX, setVerticalLineX] = React.useState(null);

  const verticalLine = transitionAction === "Delete"
    ? {
      borderLeft: "20px solid rgba(255, 100, 100, .85)",
      position: "absolute",
      zIndex: "1",
      top: "0px",
      height: "100%",
      transition: "opacity ease-in-out 0.1s",
      filter: "blur(2px)",
    }
    : {
      borderLeft: "3px solid rgba(0,0,0, 0.25)",
      position: "absolute",
      zIndex: "1",
      top: "0px",
      height: "100%",
      transition: "opacity ease-in-out 0.3s",
    };

  function sortTransitions(transitions) {
    return transitions.sort((a, b) => {
      if (a.page === b.page) {
        return a.offset - b.offset; // Sort by offset if pages are the same
      } else {
        return a.page - b.page; // Sort by page first
      }
    });
  }

  function getNextTransition(transitions, page, percentage) {
    // No stage directions exist
    if (transitions === (null || [])) return null;
    const nextTransitionPage = transitions.reduce(
      (accum, curr) =>
        (curr.page >= accum && curr.page >= page) ? curr.page : accum,
      0,
    );

    // No stage directions were found in nextTransitionPage search
    if (nextTransitionPage === 0) return null;

    // Next stage direction is on nex t page
    // so filter for all stage directions on that page
    // and choose the one with the minimum offset
    if (nextTransitionPage > page) {
      const nextTransition = transitions.filter((
        sD,
      ) => (sD.page === nextTransitionPage))
        .reduce((minObj, obj) => obj.offset < minObj.offset ? obj : minObj);
      return nextTransition === undefined ? null : nextTransition;
    }

    // Prev transitions exist on this page
    if (nextTransitionPage === page) {
      const nextTransition = transitions.filter((
        sD,
      ) => (sD.page === nextTransitionPage && sD.offset > percentage))
        .reduce((minObj, obj) => obj.offset > minObj.offset ? obj : minObj);
      return nextTransition === undefined ? null : nextTransition;
    }
  }

  function getPrevTransition(transitions, page, percentage) {
    if (transitions === (null || [])) return null;
    const prevTransitionPage = transitions.reduce(
      (accum, curr) =>
        (curr.page >= accum && curr.page <= page) ? curr.page : accum,
      0,
    );
    // No prev transitions exist
    if (prevTransitionPage === 0) return null;

    // Prev transitions exist on this page
    if (prevTransitionPage === page) {
      const prevTransition = transitions.filter((
        sD,
      ) => (sD.page === prevTransitionPage && sD.offset < percentage)).sort((
        a,
        b,
      ) => a.offset - b.offset).reverse()[0];
      return prevTransition === undefined ? null : prevTransition;
    }
    // Prev stage direction is on prev page
    // so filter for all stage directions on that page
    // and choose the one with the greatest offset
    if (prevTransitionPage < page) {
      const prevTransitions = transitions.filter((
        sD,
      ) => (sD.page === prevTransitionPage));
      const prevTransition = prevTransitions.reduce((maxObj, obj) =>
        obj.offset > maxObj.offset ? obj : maxObj
      );
      return prevTransition;
    }
  }

  function addTransition(transitions, percentage, page) {
    const prevTransition = getPrevTransition(
      transitions,
      page,
      percentage,
    );
    let newTransition = {
      page: page,
      direction: null,
      offset: percentage,
    };
    if (prevTransition === null) {
      newTransition.direction = "exit";
    } else {
      newTransition.direction = prevTransition.direction === "enter"
        ? "exit"
        : "enter";
    }
    // if a duplicate is being added let the person know
    if (
      transitions.some((sd) =>
        sd.direction === newTransition.direction &&
        sd.page === newTransition.page &&
        sd.offset === newTransition.offset
      )
    ) {
      return transitions;
    }
    const newTransitions = sortTransitions([
      ...transitions,
      newTransition,
    ]);
    setTransitions(newTransitions);
    return transitions;
  }

  // Needs a percentage
  const deleteTransition = (transitions, percentage, page) => {
    let exitNode = transitions.find((sD) =>
      sD.page === page && sD.direction === "exit" && sD.offset < percentage
    );
    let enterNode = transitions.find((sD) =>
      sD.page === page && sD.direction === "enter" && sD.offset > percentage
    );
    let exitNodeIndex = transitions.findIndex((sD) =>
      sD.page === page && sD.direction === "exit" && sD.offset < percentage
    );
    let enterNodeIndex = transitions.findIndex((sD) =>
      sD.page === page && sD.direction === "enter" && sD.offset > percentage
    );
    let newTransitions = transitions.slice(0);

    if (exitNode) {
      exitNode.direction = "delete-exit";
      // newTransitions[exitNodeIndex] = exitNode;
    }
    if (enterNode) {
      enterNode.direction = "delete-enter";
      // newTransitions[enterNodeIndex] = enterNode;
    }

    if (enterNode || exitNode) {
      newTransitions = newTransitions.filter((
        sD,
      ) => (sD.direction !== "delete-exit" &&
        sD.direction !== "delete-enter")
      );
      // transitions = newTransitions.filter((sD) =>
      //   sD.direction !== "delete-exit" && sD.direction !== "delete-enter"
      // );
      setTransitions(newTransitions);
    }
    // if percentage greate than an exit but less that an enter
    // grab those two and mark for deletion
    //
    // if starts with an enter
  };

  function handleTransition(
    percentage,
    transitionAction,
    transitions,
    page,
  ) {
    if (transitions.length > 0) {
      if (transitionAction === "Delete") {
        deleteTransition(transitions, percentage, page);
      } else {
        addTransition(transitions, percentage, page);
      }
    } else if (transitionAction !== null) {
      if (transitionAction === "Enter" || transitionAction === "Exit") {
        const newTransition = {
          page: page,
          direction: transitionAction.toLowerCase(),
          offset: percentage,
        };
        // const sectionElement = section.elements.find((elem) => elem.elementName === element);
        setTransitions([newTransition]);
        // const newSectionElements =      
        // const newStaffElements = {
        //   elements: staffElements.elements,
        //   sections: staffElements.sections.map((sec) =>
        //     sec.sectionName === section.sectionName
        //       // ? { ...sec, elements: newSectionElements }
        //       ? sec.elements.map((sec_elem) => sec_elem.elementName === element ? { ...sec_elem, transitions: [newTransition] } : sec_elem)
        //       : sec
        //   ),
        // };
        //
        // setStaffElements(newStaffElements);
      }
    }
  }

  return (
    <div
      ref={dialogueRef}
      key={characterFocusId}
      className={rowStyle}
      style={{
        userSelect: "none",
        position: "relative",
        // background: backgroundStyling,
      }}
      onClick={(e) => {
        if (dialogueRef.current !== null && showStageDirections) {
          const offsetLeft = dialogueRef.current.offsetLeft;
          const staffWidth = dialogueRef.current.offsetWidth;
          const clickX = e.clientX;
          const percentage = Math.round(
            (clickX - offsetLeft) * 100 / staffWidth,
          );
          handleTransition(
            percentage,
            transitionAction,
            transitions,
            page,
          );
        }
      }}
      // Add time function to smoot this out
      // onMouseMove={(e) => setVerticalLineX(e.clientX)}
      onMouseMove={(e) => setVerticalLineX(e.clientX)}
      onMouseLeave={() => setVerticalLineX(null)}
    // style={{ backgroundColor: highlighted ? "#fef7c3" : "#f0f0f0" }}
    >
      {showStageDirections && (
        <span
          style={{
            ...verticalLine,
            left: verticalLineX == null
              ? "0px"
              : `${verticalLineX - dialogueRef.current.offsetLeft - 8}px`,
            opacity: verticalLineX == null ? "0" : "1",
          }}
        />
      )}
      {
        /*

      */
      }
      <div
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          zIndex: "0",
          top: "0",
          left: "0",
          borderRadius: "6px",
          background: backgroundStyling,
        }}
      />
      {actualCharacterDialogue === undefined ||
        actualCharacterDialogue === ""
        ? "\u00A0"
        : actualCharacterDialogue}
    </div>
  );
}
